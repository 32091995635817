import * as React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ContactThankYou from "../components/contactForm/thankYou"

const ContactThankYouPage = () => {
    return (
    <Layout>
        <div>
            <Seo title="Kontakt" />
            <Link to="/" className="backButton">
                <div className="icon"></div>
                <div className="label">Zurück zur Übersicht</div>
            </Link>
            <div className="topic-page">
                <ContactThankYou />
            </div>
        </div>
        </Layout>
    )
}

export default ContactThankYouPage