import React, { Component } from "react"

class ContactFormThankYou extends Component {
    render() {
        return (
            <div className="full-page">
                <div className="form-box">
                    <h2>Vielen Dank für Ihre Nachricht!</h2>
                    <p>Wir werden uns zeitnah bei Ihnen melden!</p>
                </div>
            </div>
        )
    }
}  
  
export default ContactFormThankYou